import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { mapToCustomPortalError } from '../errors/mappers/custom-portal-error-mapper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next
      .handle(req)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => mapToCustomPortalError(error))));
  }
}
