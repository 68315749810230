import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.css',
})
export class BadgeComponent {
  // define more styles as soon as specified by the Design e.g. 'warning'|'info'
  @Input({ required: true }) text: string;
  @Input() type?: 'notification' | 'warning';
  @Input() icon?: string;

  get classes() {
    switch (this.type) {
      case 'warning':
        return 'bg-orange-veryLight text-orange border-orange';
      default:
        return 'bg-grey-lightGrey text-grey';
    }
  }

  get dotClasses() {
    switch (this.type) {
      case 'warning':
        return 'bg-orange';
      default:
        return '';
    }
  }
}
