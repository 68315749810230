import { ErrorType } from './ErrorType';
import { ProblemJson } from './ProblemJson';

export class CustomPortalError extends Error {
  public errorType: ErrorType;
  public errorDetails?: ProblemJson;
  constructor(message: string, errorDetails?: ProblemJson) {
    super(message);
    this.name = 'CustomPortalError';
    this.errorDetails = errorDetails;
  }
}
