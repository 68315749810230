import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { PrimaryButtonComponent } from '../../../../../shared/components/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../../../../../shared/components/secondary-button/secondary-button.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonRowComponent } from '../../../../../shared/components/button-row/button-row.component';
import { catchError, EMPTY, Observable, switchMap } from 'rxjs';
import { UserUIModel } from '../../../../../domains/organization/user/models/UserUIModel';
import { LoadingComponent } from '../../../../../shared/components/loading/loading.component';
import { SepTableSelectionService } from '../../../../../shared/components/sep-table/services/sep-table-selection.service';
import { CommonModule } from '@angular/common';
import { Select } from '@ngxs/store';
import { CurrentUserState } from '../../../../../core/store/current-user/current-user.state';
import { LicenseInfoCardComponent } from '../../../../../domains/payment/subscription/components/license-info-card/license-info-card.component';
import { InfoCardComponent } from '../../../../../shared/components/info-card/info-card.component';
import { easeInAnimation } from '../../../../../shared/animations/ease-in-animation';
import { SubscriptionService } from '../../../../../domains/payment/subscription/services/subscription.service';
import { TextWithLinkComponent } from '../../../../../shared/components/text-with-link/text-with-link.component';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';
import { CourseEnrollmentTableComponent } from '../../../../../domains/academy/course/components/course-enrollment-table/course-enrollment-table.component';
import { EnrollmentContainerBase } from '../EnrollmentContainerBase';
import { LearningPlanDataService } from '../../../../../domains/academy/learning-plan/services/learning-plan-data.service';
import { LearningPlanService } from '../../../../../domains/academy/learning-plan/services/learning-plan.service';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { UserService } from '../../../../../domains/organization/user/services/user.service';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { LearningPlanEnrollmentTableComponent } from '../../../../../domains/academy/learning-plan/components/learning-plan-enrollment-table/learning-plan-enrollment-table.component';
import { LanguageService } from '../../../../../core/services/language.service';
import { EnrollmentNotificationService } from '../../../../../core/services/enrollment-notification.service';

@Component({
  selector: 'app-learning-plan-enrollment-container',
  standalone: true,
  imports: [
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    CourseEnrollmentTableComponent,
    CommonModule,
    TranslocoDirective,
    ButtonRowComponent,
    LoadingComponent,
    LicenseInfoCardComponent,
    InfoCardComponent,
    TextWithLinkComponent,
    LearningPlanEnrollmentTableComponent,
  ],
  providers: [SepTableSelectionService],
  animations: [easeInAnimation],
  templateUrl: './learning-plan-enrollment-container.component.html',
  styleUrl: './learning-plan-enrollment-container.component.css',
})
export class LearningPlanEnrollmentContainerComponent extends EnrollmentContainerBase implements OnInit {
  @Output() userCreationButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() abortButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() studentSuccessfullyEnrolledEvent: EventEmitter<void> = new EventEmitter<void>();
  @Select(CurrentUserState.numberOfLicences)
  numberOfLicenses$: Observable<number>;

  constructor(
    private readonly learningPlanDataService: LearningPlanDataService,
    private readonly learningPlanService: LearningPlanService,
    private readonly snackbarService: SnackbarService,
    private readonly enrollmentNotificationService: EnrollmentNotificationService,
    languageService: LanguageService,
    subscriptionService: SubscriptionService,
    userService: UserService,
    analyticsService: AnalyticsService,
    tableSelectionService: SepTableSelectionService<UserUIModel>,
  ) {
    super(languageService, subscriptionService, userService, analyticsService, tableSelectionService);
  }
  ngOnInit(): void {
    this.getStudentsEligibleForEnrollment();
    this.getAvailableLicenses();
    this.observeTableSelection();
  }

  getStudentsEligibleForEnrollment() {
    const learningPlanId = this.learningPlanDataService.getLearningPlanInstanceId();
    if (learningPlanId) {
      this.learningPlanService
        .getLearningPlanEnrollments(learningPlanId)
        .pipe(
          switchMap((res) => {
            const enrolledStudents = res.students;
            return this.getEligibleStudents(enrolledStudents);
          }),
          catchError((error: CustomPortalError) => {
            this.snackbarService.error(error.errorType);
            return EMPTY;
          }),
        )
        .subscribe();
    }
  }

  enrollStudents() {
    this.isLoading = true;
    const learningPlanId = this.learningPlanDataService.getLearningPlanInstanceId();
    const students = this.selectedStudents.map((student) => ({
      id: student.id,
    }));
    if (students.length && learningPlanId) {
      this.learningPlanService.enrollStudentsToLearningPlan(students, learningPlanId).subscribe({
        next: () => {
          this.isLoading = false;
          this.enrollmentNotificationService.setEnrollmentChanged();
          this.analyticsService.track('students_enrolled', {
            courseId: learningPlanId,
            students: students,
          });
          this.studentSuccessfullyEnrolledEvent.emit();
        },
        complete: () => {
          this.snackbarService.success('portal.learningPlans.successfulEnrollmentSnackbar', {
            count: students.length,
            learningPlan: this.languageService.translateOnRuntime(
              'portal.learningPlans.learningPlanNameKeys.' + this.learningPlanDataService.getLearningPlanName(),
            ),
          });
        },
        error: (error: CustomPortalError) => {
          this.isLoading = false;
          this.snackbarService.error(error.errorType);
        },
      });
    }
  }

  @HostListener('window:keydown.enter', ['$event'])
  onEnterKey() {
    if (this.selectedStudents.length > 0 && this.canEnroll) {
      this.enrollStudents();
    }
  }
}
