import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LearningPlanDataService {
  private learningPlanInstanceId: string | undefined = undefined;
  private learningPlanName: string | undefined = undefined;

  constructor() {}

  getLearningPlanInstanceId(): string | undefined {
    return this.learningPlanInstanceId;
  }

  setLearningPlanInstanceId(id: string): void {
    this.learningPlanInstanceId = id;
  }

  setLearningPlaneName(name: string) {
    this.learningPlanName = name;
  }

  getLearningPlanName(): string | undefined {
    return this.learningPlanName;
  }
}
