import { Injectable } from '@angular/core';
import { ParseResult, parse } from 'papaparse';

import * as jschardet from 'jschardet';

const MAX_FILE_SIZE = 300 * 1024; // 300 KB in bytes
const MAX_CHARS_PER_ENTRY = 50;

export interface Validator {
  fieldName: string;
  required: boolean;
  validatorFn?: (value: string) => boolean;
}

export interface ValidationResult {
  rowIndex: number;
  isValid: boolean;
  errors?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CsvUploadService {
  readonly maxFileSize = MAX_FILE_SIZE;
  readonly maxCharsPerEntry = MAX_CHARS_PER_ENTRY;

  constructor() {}

  public async parseCsvFromUploadedFile(
    file: File,
    validators: Validator[],
  ): Promise<{ data: Array<Record<string, string>>; validationResults: ValidationResult[] }> {
    return new Promise((resolve, reject) => {
      if (file.type !== 'text/csv') {
        reject(new Error('INVALID_FILE_TYPE'));
        return;
      }

      if (file.size > this.maxFileSize) {
        reject(new Error('INVALID_FILE_SIZE'));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;

        const detected = jschardet.detect(new TextDecoder().decode(arrayBuffer));
        const encoding = detected.confidence === 1 ? detected.encoding : 'utf-8';

        parse(file, {
          header: true,
          dynamicTyping: true,
          encoding: encoding,
          skipEmptyLines: 'greedy',
          delimitersToGuess: [',', ';'],
          transformHeader: (headerName) => this.processHeader(headerName),
          complete: (results: ParseResult<Record<string, string>>) => {
            try {
              // Validate headers
              const parsedHeaders = results.meta.fields ?? [];
              this.validateHeaders(validators, parsedHeaders);

              // Process the data (trim and validate)
              results.data = this.trimCsvData(results.data);
              const validationResults = this.validateRows(results, validators);

              resolve({ data: results.data, validationResults });
            } catch (error) {
              reject(error instanceof Error ? error : new Error(String(error)));
            }
          },
          error: () => {
            reject(new Error('CSV_PARSING_FAILED'));
          },
        });
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private processHeader(headerName: string): string {
    return headerName.trim();
  }

  private trimCsvData(data: Array<Record<string, string>>): Array<Record<string, string>> {
    return data.map((row) => {
      const trimmedRow: Record<string, string> = {};
      for (const key in row) {
        if (Object.prototype.hasOwnProperty.call(row, key) && key !== '__parsed_extra') {
          trimmedRow[key] = row[key] ? row[key].trim() : '';
        }
      }
      return trimmedRow;
    });
  }

  private validateHeaders(validators: Validator[], parsedHeaders: string[]): void {
    validators.forEach((validator) => {
      if (!parsedHeaders.includes(validator.fieldName)) {
        throw new Error(`FILE_INCOMPLIANT_WITH_TEMPLATE`);
      }
    });
  }

  private validateRows<T extends Record<string, string>>(
    results: ParseResult<T>,
    validators: Validator[],
  ): ValidationResult[] {
    return results.data.map((row, index) => {
      const errors: string[] = [];
      validators.forEach(({ validatorFn, fieldName, required }) => {
        const value = row[fieldName];

        if (required && !value) {
          errors.push(`${fieldName.toUpperCase()}_MISSING`);
          return;
        }
        if (value.length > this.maxCharsPerEntry) {
          errors.push(`${fieldName.toUpperCase()}_EXCEEDS_CHAR_LENGTH`);
        }
        if (this.containsControlCharacters(value) || (validatorFn && !validatorFn(value))) {
          errors.push(`${fieldName.toUpperCase()}_INVALID`);
        }
      });
      return { rowIndex: index, isValid: errors.length === 0, errors: errors.length > 0 ? errors : undefined };
    });
  }

  private containsControlCharacters(value: string): boolean {
    const controlCharacters = /[<>;\\&(){}'"/=*|$]/;
    return controlCharacters.test(value);
  }
}
