import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UserCardComponent } from '../../../../../shared/components/user-card/user-card.component';
import { SepTableComponent, SepTableType } from '../../../../../shared/components/sep-table/sep-table.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { BadgeComponent } from '../../../../../shared/components/badge/badge.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EnrollmentTableBaseComponent } from '../../../base/EnrollmentTableBaseComponent';
import { StudentEligibleForEnrollmentBaseUIModel } from '../../../base/EnrolledStudentBaseUIModel';

@Component({
  selector: 'app-course-enrollment-table',
  standalone: true,
  imports: [UserCardComponent, SepTableComponent, TranslocoDirective, BadgeComponent, MatIconModule, MatTooltipModule],
  templateUrl: './course-enrollment-table.component.html',
})
export class CourseEnrollmentTableComponent extends EnrollmentTableBaseComponent {
  @Input({ required: true }) students!: StudentEligibleForEnrollmentBaseUIModel[];
  @Output() primaryButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('personCard', { static: true }) personCard!: TemplateRef<{ $implicit: string }>;
  sepTableType = SepTableType;

  protected configureColumns(): void {
    this.columns[0].renderComponent = this.personCard;
  }
}
