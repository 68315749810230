import { Component, Input } from '@angular/core';
import { BadgeComponent } from '../../../../../shared/components/badge/badge.component';
import { PrimaryButtonComponent } from '../../../../../shared/components/primary-button/primary-button.component';
import { CourseInstanceDTO } from '../../models/CourseDTO';
import { TranslocoModule } from '@jsverse/transloco';
import { DoughnutChartComponent } from '../../../../../shared/components/doughnut-chart/doughnut-chart.component';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-course-instance-info-card',
  standalone: true,
  imports: [BadgeComponent, PrimaryButtonComponent, TranslocoModule, MatIconModule, DoughnutChartComponent, DatePipe],
  templateUrl: './course-instance-info-card.component.html',
  styleUrl: './course-instance-info-card.component.css',
})
export class CourseInstanceInfoCardComponent {
  @Input({ required: true }) courseInstance: CourseInstanceDTO;

  constructor(
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
  ) {}

  goToCourseDetails(id: string): void {
    this.analyticsService.track('course_setup_clicked');
    void this.router.navigate(['/portal/courses', id]);
  }

  getCompletionPercentage(): number {
    const { numberOfCourseCompletedStudents, numberOfEnrolledStudents } = this.courseInstance;
    return numberOfEnrolledStudents > 0
      ? Math.round((numberOfCourseCompletedStudents / numberOfEnrolledStudents) * 100)
      : 0;
  }
}
