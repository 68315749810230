<div *transloco="let t" class="flex h-full flex-col">
  <header class="mt-5 pl-6">
    <p class="mb-8 text-lg font-semibold">
      {{ t('portal.users.creation.createText') }}
    </p>
  </header>

  <div class="flex grow items-center justify-center">
    <div class="w-full px-6 py-20">
      <form
        class="flex h-12"
        [formGroup]="userCreationForm"
        role="form"
        (keydown.enter)="
          userCreationForm.valid ? handleUserCreationSubmit() : null
        "
      >
        <app-text-input
          class="mr-2 w-1/4 grow"
          [control]="userCreationForm.controls.firstName"
          [controlName]="'firstName'"
          [placeholder]="t('portal.users.creation.form.firstName')"
        />
        <app-text-input
          class="mr-2 w-1/4 grow"
          [control]="userCreationForm.controls.lastName"
          [controlName]="'lastName'"
          [placeholder]="t('portal.users.creation.form.lastName')"
        />
        <app-text-input
          class="w-1/2 grow"
          [control]="userCreationForm.controls.email"
          [controlName]="'email'"
          [placeholder]="t('portal.users.creation.form.email')"
        />
      </form>
      <div
        class="absolut my-10 flex items-center justify-between text-grey-lightGrey"
      >
        <mat-divider class="material-divider flex-auto"></mat-divider>
        <span class="mx-4 text-sm text-primary">{{ t('general.or') }}</span>
        <mat-divider class="material-divider flex-auto"></mat-divider>
      </div>
      <app-secondary-button
        [text]="t('portal.users.creation.csvUpload.csvUpload')"
        icon="cloud_upload"
        (buttonClicked)="handleCsvButtonClick()"
      ></app-secondary-button>
    </div>
  </div>

  <footer class="w-full border-t-2 border-grey-lightGrey px-4 py-1">
    <app-button-row
      [primaryButtonText]="t('portal.users.creation.create')"
      [secondaryButtonText]="t('general.back')"
      (primaryButtonClickedEvent)="handleUserCreationSubmit()"
      (secondaryButtonClickedEvent)="handleBackButtonClick()"
      [isLoading]="isLoading"
      [disablePrimaryButton]="!userCreationForm.valid || isLoading"
    ></app-button-row>
  </footer>
</div>
