import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CurrentUserState } from '../../../core/store/current-user/current-user.state';
import { AsyncPipe } from '@angular/common';
import { Subscription } from '../../../core/store/current-user/models/CurrentUserUIModel';
import { CourseInstancesOverviewComponent } from '../../../domains/academy/course/components/course-instances-overview/course-instances-overview.component';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [TranslocoModule, AsyncPipe, CourseInstancesOverviewComponent],
  animations: [easeInAnimationFast],
  templateUrl: './overview-container.component.html',
  styleUrl: './overview-container.component.css',
})
export class OverviewContainerComponent {
  @Select(CurrentUserState.subscription) subscription$: Observable<Subscription | null>;
}
