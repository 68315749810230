<div
  [ngClass]="
    'inline-flex items-center px-3 py-1 rounded-full border ' + classes
  "
>
  @if (icon) {
    <mat-icon class="material-icons-outlined p-0.5 text-sm">{{
      icon
    }}</mat-icon>
  } @else {
    <span [ngClass]="'w-2 h-2 my-2 mr-2 rounded-full ' + dotClasses"></span>
  }
  <span class="text-xs">{{ text }}</span>
</div>
