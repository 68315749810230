import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  UserCreationRequestDTO,
  UserCreationResponseDTO,
  UserDisableEnableRequestDTO,
  UserDTO,
  UsersResponseDTO,
  UserUpdateRequestDTO,
} from '../models/UserDTO';
import {
  toUserDisableEnableRequestDTO,
  toUsersCreationRequestDTO,
  toUserUpdateRequestDTO,
} from '../mappers/dto-mappers';
import { CurrentUserState } from '../../../../core/store/current-user/current-user.state';
import { PortalApiRepository } from '../../../../core/repositories/portal-api-repository';
import { UserUIModel } from '../models/UserUIModel';

@Injectable({
  providedIn: 'root',
})
export class UserService extends PortalApiRepository {
  constructor() {
    super();
  }
  createUsers(users: UserDTO[] | undefined): Observable<UserCreationResponseDTO> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);
    const path = `/organizations/${orgId}/users`;
    const payload = toUsersCreationRequestDTO(users);
    return this.post<UserCreationRequestDTO, UserCreationResponseDTO>(path, payload);
  }

  getUsers(): Observable<UsersResponseDTO> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);
    const path = `/organizations/${orgId}/users`;

    return this.get<UsersResponseDTO>(path);
  }

  disable(users: UserUIModel[]): Observable<void> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);
    const path = `/organizations/${orgId}/users/disable`;
    const payload = toUserDisableEnableRequestDTO(users);

    return this.post<UserDisableEnableRequestDTO, void>(path, payload);
  }

  enable(users: UserUIModel[]): Observable<void> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);
    const path = `/organizations/${orgId}/users/enable`;

    const payload = toUserDisableEnableRequestDTO(users);
    return this.post<UserDisableEnableRequestDTO, void>(path, payload);
  }

  unassignLicense(licenseId: string): Observable<void> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/licenses/${licenseId}/unassign`;
    return this.post<void, void>(path);
  }

  updateUser(userId: string, userData: Partial<UserUIModel>): Observable<void> {
    const orgId = this.store.selectSnapshot(CurrentUserState.organizationId);

    const path = `/organizations/${orgId}/users/${userId}`;

    const payload = toUserUpdateRequestDTO(userData);
    return this.patch<UserUpdateRequestDTO, void>(path, payload);
  }
}
