import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  LicenseStatus,
  SubscriptionLicensesResponseDTO,
} from '../../../../domains/payment/subscription/models/SubscriptionDTO';
import { SubscriptionService } from '../../../../domains/payment/subscription/services/subscription.service';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../domains/organization/user/services/user.service';
import { UserResponseDTO } from '../../../../domains/organization/user/models/UserDTO';
import { LanguageService } from '../../../../core/services/language.service';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { SepTableSelectionService } from '../../../../shared/components/sep-table/services/sep-table-selection.service';
import { UserUIModel } from '../../../../domains/organization/user/models/UserUIModel';
import { StudentEligibleForEnrollmentBaseUIModel } from '../../../../domains/academy/base/EnrolledStudentBaseUIModel';

@Injectable({
  providedIn: 'root',
})
export abstract class EnrollmentContainerBase implements OnDestroy {
  isLoading = false;
  canEnroll = false;
  studentsEligibleForEnrollment: StudentEligibleForEnrollmentBaseUIModel[] | undefined = undefined;
  selectedStudents: StudentEligibleForEnrollmentBaseUIModel[];
  availableLicenses$: Observable<number>;
  supportEmail = environment.general.hvsSupportContactEmail;
  abstract abortButtonClickEvent: EventEmitter<void>;
  abstract studentSuccessfullyEnrolledEvent: EventEmitter<void>;
  abstract userCreationButtonClickEvent: EventEmitter<void>;

  protected destroy$: Subject<void> = new Subject();

  constructor(
    protected readonly languageService: LanguageService,
    protected readonly subscriptionService: SubscriptionService,
    protected readonly userService: UserService,
    protected readonly analyticsService: AnalyticsService,
    protected readonly tableSelectionService: SepTableSelectionService<UserUIModel>,
  ) {}

  abstract getStudentsEligibleForEnrollment(): void;
  abstract enrollStudents(): void;

  observeTableSelection() {
    this.tableSelectionService.selection$.pipe(takeUntil(this.destroy$)).subscribe((selection) => {
      this.selectedStudents = selection.selected;
      this.checkLicenseEligibilityForEnrollment();
    });
  }

  checkLicenseEligibilityForEnrollment(): void {
    this.availableLicenses$
      .pipe(
        takeUntil(this.destroy$),
        map((availableLicenses) => availableLicenses - this.countSelectedStudentsWithoutLicenseAssigned()),
        map((licenseDiff) => licenseDiff >= 0),
      )
      .subscribe((canEnroll) => {
        this.canEnroll = canEnroll;
      });
  }

  countSelectedStudentsWithoutLicenseAssigned(): number {
    return this.selectedStudents.reduce((count, student) => {
      if (!student.license?.status) {
        count += 1;
      }
      return count;
    }, 0);
  }

  /*we compare enrolled students to all users in the organization.
      The diff reflects students that are eligible to be enrolled in the course.*/
  getEligibleStudents(enrolledStudents: Array<{ id: string }>): Observable<void> {
    return this.userService.getUsers().pipe(
      map((responseDTO) => {
        const usersEligibleForEnrollment = responseDTO.users.filter(
          (user) => !enrolledStudents.some((enrolledStudent) => enrolledStudent.id === user.id),
        );
        this.studentsEligibleForEnrollment = this.transformUsersToStudents(usersEligibleForEnrollment);
      }),
    );
  }

  getAvailableLicenses() {
    this.availableLicenses$ = this.subscriptionService.getLicenses().pipe(
      takeUntil(this.destroy$),
      map(
        (response: SubscriptionLicensesResponseDTO) =>
          response.licenses.filter((license) => license.status === LicenseStatus.AVAILABLE).length,
      ),
    );
  }

  getEnrollmentDescription() {
    const numberOfLicenses = this.countSelectedStudentsWithoutLicenseAssigned();
    if (numberOfLicenses === 0) {
      return this.languageService.translateOnRuntime('portal.courses.enrollment.licenses.assignmentExplanationZero');
    } else if (numberOfLicenses === 1) {
      return this.languageService.translateOnRuntime(
        'portal.courses.enrollment.licenses.assignmentExplanationSingular',
      );
    } else {
      return this.languageService.translateOnRuntime('portal.courses.enrollment.licenses.assignmentExplanationPlural', {
        numberOfLicenses,
      });
    }
  }

  handleUserCreationButtonClick(): void {
    this.analyticsService.track('create_employees_clicked');
    this.userCreationButtonClickEvent.emit();
  }

  handleAbortButtonClick(): void {
    this.abortButtonClickEvent.emit();
  }

  private transformUsersToStudents(users: UserResponseDTO[]): StudentEligibleForEnrollmentBaseUIModel[] {
    return users.map((user) => {
      const username = `${user.firstName} ${user.lastName}`;
      const { email, id, licenses } = user;
      return {
        id,
        username,
        email,
        license: licenses.length ? licenses[0] : null,
      };
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
