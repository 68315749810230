<div>
  <ng-template #organization let-element>
    <div class="m-4 flex items-center space-x-4" *transloco="let t">
      <p class="flex-1">{{ element.name }}</p>
      <app-text-button
        class="flex-1"
        (click)="handleTakeOverButtonClick(element)"
        >{{ t('admin.organizations.takeOverButtonText') }}</app-text-button
      >
      <app-text-button class="flex-1">{{
        t('admin.organizations.changeSettingsButtonText')
      }}</app-text-button>
    </div>
  </ng-template>

  @if (organizations$ | async; as organizations) {
    <app-sep-table
      *transloco="let t"
      [columns]="columns"
      [dataSource]="organizations"
      [noDataText]="t('admin.organizations.noDataTableText')"
      [checkboxSelectedText]="t('admin.organizations.checkboxSelectedText')"
      [hasBulkActionButton]="true"
      [hideColumnHeader]="true"
    ></app-sep-table>
  }
</div>
