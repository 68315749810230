/*
 * The Original Code is Mozilla Universal charset detector code.
 *
 * The Initial Developer of the Original Code is
 * Netscape Communications Corporation.
 * Portions created by the Initial Developer are Copyright (C) 2001
 * the Initial Developer. All Rights Reserved.
 *
 * Contributor(s):
 *   António Afonso (antonio.afonso gmail.com) - port to JavaScript
 *   Mark Pilgrim - port to Python
 *   Shy Shalom - original C code
 *
 * This library is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 2.1 of the License, or (at your option) any later version.
 *
 * This library is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public
 * License along with this library; if not, write to the Free Software
 * Foundation, Inc., 51 Franklin St, Fifth Floor, Boston, MA
 * 02110-1301  USA
 */

var constants = require('./constants');
function CodingStateMachine(sm) {
  var self = this;
  function init(sm) {
    self._mModel = sm;
    self._mCurrentBytePos = 0;
    self._mCurrentCharLen = 0;
    self.reset();
  }
  this.reset = function () {
    this._mCurrentState = constants.start;
  };
  this.nextState = function (c) {
    // for each byte we get its class
    // if it is first byte, we also get byte length
    var byteCls = this._mModel.classTable[c.charCodeAt(0)];
    if (this._mCurrentState == constants.start) {
      this._mCurrentBytePos = 0;
      this._mCurrentCharLen = this._mModel.charLenTable[byteCls];
    }
    // from byte's class and stateTable, we get its next state
    this._mCurrentState = this._mModel.stateTable[this._mCurrentState * this._mModel.classFactor + byteCls];
    this._mCurrentBytePos++;
    return this._mCurrentState;
  };
  this.getCurrentCharLen = function () {
    return this._mCurrentCharLen;
  };
  this.getCodingStateMachine = function () {
    return this._mModel.name;
  };
  init(sm);
}
module.exports = CodingStateMachine;