import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

declare global {
  interface Window {
    /* eslind doesn't allow for uppercase in properties, nevertheless we depend on the specification provided by Cookiebot.*/
    // eslint-disable-next-line
    Cookiebot?: {
      consent?: {
        statistics: boolean;
      };
    };
  }
}

/*This service listens for consent status changes from Cookiebot. 
It updates its internal observable with the latest consent status, allowing other parts of the application to react to changes in user consent preferences. 
Documentation: https://www.cookiebot.com/en/developer/*/
@Injectable({
  providedIn: 'root',
})
export class CookiebotListenerService {
  statisticsConsentSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  statisticsConsent$: Observable<boolean | null> = this.statisticsConsentSubject.asObservable();

  constructor() {
    this.initCookiebotListener();
  }

  private initCookiebotListener(): void {
    if (window.Cookiebot) {
      this.checkConsentStatus();
      window.addEventListener('CookiebotOnConsentReady', () => {
        this.checkConsentStatus();
      });
    }
  }

  private checkConsentStatus(): void {
    if (window.Cookiebot?.consent) {
      const consentStatus = window.Cookiebot.consent.statistics;
      this.statisticsConsentSubject.next(consentStatus);
    }
  }
}
