import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TextInputComponent } from '../../../../../shared/components/text-input/text-input.component';
import { CheckboxComponent } from '../../../../../shared/components/checkbox/checkbox.component';
import { PrimaryButtonComponent } from '../../../../../shared/components/primary-button/primary-button.component';
import { OrganizationService } from '../../services/organization.service';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { DataProcessingAgreementResponseDTO } from '../../models/DataProcessingAgreementDTO';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { forbiddenCharactersValidator } from '../../../../../shared/validators/custom-validators';

export interface ContractFormControls {
  agreement: FormControl<boolean>;
  date: FormControl<string>;
  fullName: FormControl<string>;
  responsibleBody: FormControl<string>;
}

@Component({
  selector: 'app-data-processing-agreement',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    CheckboxComponent,
    PrimaryButtonComponent,
    MatIconModule,
    TranslocoModule,
  ],
  providers: [DatePipe],
  templateUrl: './data-processing-agreement.component.html',
  styleUrl: './data-processing-agreement.component.css',
})
export class DataProcessingAgreementComponent implements OnInit {
  contractForm: FormGroup<ContractFormControls>;
  isLoading: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly organizationService: OrganizationService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    const currentDate = this.formatDate(new Date());
    this.contractForm = this.fb.group<ContractFormControls>({
      agreement: this.fb.nonNullable.control(false, Validators.requiredTrue),
      date: this.fb.nonNullable.control({ value: currentDate, disabled: true }, Validators.required),
      fullName: this.fb.nonNullable.control('', [
        Validators.required,
        Validators.maxLength(50),
        forbiddenCharactersValidator(),
      ]),
      responsibleBody: this.fb.nonNullable.control('', [Validators.maxLength(50), forbiddenCharactersValidator()]),
    });

    this.getDataProcessingAgreement();
  }

  onSubmit(): void {
    this.contractForm.disable();
    this.isLoading = true;
    this.organizationService.saveDataProcessingAgreement(this.contractForm.value).subscribe({
      next: () => {
        this.isLoading = false;
        this.getDataProcessingAgreement();
      },
      complete: () => {
        this.snackbarService.success('portal.settings.dataProcessingContract.successfulDPASubmissionSnackbar');
      },
      error: (error: CustomPortalError) => {
        this.isLoading = false;
        this.snackbarService.error(error.errorType);
      },
    });
  }

  download(fileName: string) {
    this.organizationService.downloadFile(fileName).subscribe((data) => {
      this.openFile(data);
    });
  }

  private openFile(data: Blob) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  private getDataProcessingAgreement() {
    this.organizationService.getDataProcessingAgreement().subscribe({
      next: (res: DataProcessingAgreementResponseDTO) => {
        this.contractForm.patchValue({
          agreement: true,
          date: this.formatDate(res.dataProcessingAgreement.date),
          fullName: res.dataProcessingAgreement.signatory,
          responsibleBody: res.dataProcessingAgreement.responsibleBody ?? '-',
        });
        this.contractForm.disable();
      },
      error: () => {
        console.info('No data processing contract signed');
      },
    });
  }

  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd.MM.yyyy') ?? '';
  }
}
