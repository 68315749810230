import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserTableComponent } from '../user-table/user-table.component';
import { LicenseOverviewCardComponent } from '../../../../payment/subscription/components/license-overview-card/license-overview-card.component';
import { UsersResponseDTO } from '../../models/UserDTO';
import { UserUIModel } from '../../models/UserUIModel';
import { AsyncPipe } from '@angular/common';
import { Observable, Subject, takeUntil, map, catchError, EMPTY } from 'rxjs';
import { toUserUIModel } from '../../mappers/dto-mappers';
import { UserCreationNotificationService } from '../../services/user-creation-notification.service';
import { UserService } from '../../services/user.service';
import { easeInAnimationFast } from '../../../../../shared/animations/ease-in-animation';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../../../core/services/snackbar.service';

@Component({
  selector: 'app-user-management-overview',
  standalone: true,
  imports: [UserTableComponent, AsyncPipe, LicenseOverviewCardComponent],
  animations: [easeInAnimationFast],
  templateUrl: './user-management-overview.component.html',
})
export class UserManagementOverviewComponent implements OnInit, OnDestroy {
  users$: Observable<UserUIModel[]>;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly userService: UserService,
    private readonly userCreationNotificationService: UserCreationNotificationService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
    this.userCreationNotificationService
      .getUserChangedNotification()
      .pipe(takeUntil(this.destroy$))
      .subscribe((successNotification) => {
        if (successNotification) {
          this.fetchUsers();
        }
      });
  }

  fetchUsers() {
    this.users$ = this.userService.getUsers().pipe(
      map((res: UsersResponseDTO) => toUserUIModel(res.users)),
      catchError((error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        return EMPTY;
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
