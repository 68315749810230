import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from './core/layout/sidebar/sidebar.component';
import { LanguageSwitcherComponent } from './shared/components/language-switcher/language-switcher.component';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { CookiebotListenerService } from './core/services/cookiebot-listener.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, LanguageSwitcherComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly msalService: MsalService,
    private readonly analyticsService: AnalyticsService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly cookiebotListenerService: CookiebotListenerService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.subscribeToCookieConsentChanges();

    this.msalService
      .handleRedirectObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: AuthenticationResult | null) => {
        if (result) {
          const { oid } = this.authService.extractUserInfo(result);
          if (!this.analyticsService.isUserIdentified && oid) {
            this.analyticsService.identify(oid);
          }
          this.analyticsService.track('logged_in');
        }
      });

    this.observeLogoutEvents();
  }

  private subscribeToCookieConsentChanges() {
    this.cookiebotListenerService.statisticsConsent$.pipe(takeUntil(this.destroy$)).subscribe(async (consentGiven) => {
      if (consentGiven === null) {
        return;
      }
      this.analyticsService.setConsent(consentGiven);

      if (!this.analyticsService.isUserIdentified) {
        const { oid } = this.authService.extractUserInfo(await this.authService.getMsalAuthenticationResult());
        oid ? this.analyticsService.identify(oid) : null;
      }
    });
  }

  private observeLogoutEvents(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((event: EventMessage) => event.eventType === EventType.LOGOUT_SUCCESS),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.analyticsService.track('logged_out');
        this.analyticsService.reset();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
