import { AsyncPipe, CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { SepTableService } from './services/sep-table.service';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { ButtonType } from '../primary-button/ButtonType';
import { Column } from './Column';
import { TableBulkAction, TableRowAction } from './Action';
import { FilterCategory, SepFilterComponent } from '../sep-filter/sep-filter.component';
import { MatSort } from '@angular/material/sort';
import { TranslocoPipe } from '@jsverse/transloco';

export enum SepTableType {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

@Component({
  selector: 'app-sep-table',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIcon,
    MatMenuModule,
    MatTableModule,
    NgTemplateOutlet,
    PrimaryButtonComponent,
    AsyncPipe,
    SepFilterComponent,
    TranslocoPipe,
  ],
  providers: [SepTableService],
  templateUrl: './sep-table.component.html',
})
export class SepTableComponent<T> implements OnChanges {
  sepTableType = SepTableType;
  buttonType = ButtonType;
  @Input() columns: Array<Column<T, string | { $implicit: string }>> = [];
  @Input() dataSource: T[] = [];
  @Input() bulkActions: Array<TableBulkAction<T>> = [];
  @Input() rowActions: Array<TableRowAction<T>> = [];
  @Input() noDataText: string;
  @Input() name?: string;
  @Input() primaryButtonTitle?: string;
  @Input() filterCategories?: FilterCategory[];
  @Input() checkboxSelectedText?: string;
  @Input() hasCheckbox?: boolean = true;
  @Input() hasBulkActionButton?: boolean = false;
  @Input() hideColumnHeader?: boolean = false;
  @Input() type?: SepTableType = SepTableType.LARGE;
  @Input() allowExport = false;
  @Output() primaryButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() exportContentButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  targetedRow: T | null = null;
  currentSortColumn: keyof T | null = null;
  sortOrderAscending = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public sepTableService: SepTableService<T>) {}

  ngOnChanges(changes?: SimpleChanges): void {
    const val = changes?.['dataSource'] ? (changes['dataSource'].currentValue as T[] | undefined) : undefined;
    if (val) {
      this.sepTableService.setDataSource(val);
    }
  }

  get displayedColumns(): string[] {
    const hasRowActions = this.rowActions.length;
    let columns = this.columns.map(({ headerName }) => headerName);
    if (this.hasCheckbox) {
      columns = ['select'].concat(columns);
    }
    if (hasRowActions) {
      columns.push('rowActions');
    }
    return columns;
  }

  toggleAllRows(): void {
    this.sepTableService.toggleAllRows();
  }

  selectRow(row: T): void {
    this.sepTableService.selectRow(row);
  }

  get selection$() {
    return this.sepTableService.selection$;
  }

  setTargetedRow(row: T) {
    this.targetedRow = row;
  }

  getLabel(action: TableRowAction<T>, targetRow: T): string {
    return action.dynamicLabel ? action.dynamicLabel(targetRow) : action.label;
  }

  checkDisabled(action: TableRowAction<T>, targetRow: T) {
    return action.checkDisabled ? action.checkDisabled(targetRow) : false;
  }

  primaryButtonClicked(): void {
    this.primaryButtonClickedEvent.emit();
  }

  applyFilter(filterCategories: FilterCategory[]) {
    this.sepTableService.applyFilter(filterCategories);
  }

  sortColumn(columnKey: keyof T) {
    if (this.currentSortColumn === columnKey) {
      this.sortOrderAscending = !this.sortOrderAscending;
    } else {
      this.sortOrderAscending = true;
      this.currentSortColumn = columnKey;
    }
    this.sepTableService.sortOrGroupData(columnKey, this.sortOrderAscending);
  }
  exportContentButtonClicked(): void {
    this.exportContentButtonClickedEvent.emit();
  }
}
