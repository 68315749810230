import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '../../../../core/services/language.service';
import { UserUIModel } from '../models/UserUIModel';
import { DialogModel } from '../../../../shared/components/dialog/dialog';

@Injectable({
  providedIn: 'root',
})
export class UserDialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
  ) {}

  showEditUserDialog(templateRef: TemplateRef<Element>, onConfirm: () => void): DialogModel {
    const editUserDialog = new DialogModel(this.dialog);
    editUserDialog.closable = false;
    editUserDialog.headline = this.languageService.translateOnRuntime('portal.users.table.editUserDialog.headline');
    editUserDialog.buttonRowData = {
      confirmButtonText: this.languageService.translateOnRuntime('portal.users.table.editUserDialog.confirmButtonText'),
      confirmButtonAction: onConfirm,
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };
    editUserDialog.contentTemplate = templateRef;

    editUserDialog.openDialog();

    return editUserDialog;
  }

  showLicenseUnassignmentConfirmationDialog(user: UserUIModel, onConfirm: () => void): DialogModel {
    const licenseUnassignmentConfirmationDialog = new DialogModel(this.dialog);
    licenseUnassignmentConfirmationDialog.closable = true;
    licenseUnassignmentConfirmationDialog.headline = this.languageService.translateOnRuntime(
      'portal.users.table.unassignLicenseConfirmationDialog.headline',
    );
    licenseUnassignmentConfirmationDialog.description = this.languageService.translateOnRuntime(
      'portal.users.table.unassignLicenseConfirmationDialog.description',
      { name: user.username },
    );
    licenseUnassignmentConfirmationDialog.buttonRowData = {
      confirmButtonText: this.languageService.translateOnRuntime(
        'portal.users.table.unassignLicenseConfirmationDialog.confirmButtonText',
      ),
      confirmButtonAction: onConfirm,
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };

    licenseUnassignmentConfirmationDialog.openDialog();

    return licenseUnassignmentConfirmationDialog;
  }
}
