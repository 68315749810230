<div *transloco="let t">
  <h2 class="mb-5 text-xl font-semibold">
    {{ t('portal.settings.generalSettingsLanguage.headline') }}
  </h2>
  <div class="inline-flex rounded-lg bg-gray-100 p-1">
    @for (lang of languages; track lang) {
      <button
        (click)="changeSiteLanguage(lang.code)"
        [ngClass]="{
          'bg-white text-blue-600 shadow-md': activeLangCode === lang.code,
          'bg-gray-100 text-gray-800': activeLangCode !== lang.code
        }"
        class="rounded-lg px-4 py-2 font-medium"
      >
        {{ lang.label }}
      </button>
    }
  </div>
</div>
