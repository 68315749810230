import { DataProcessingAgreementRequestDTO } from '../models/DataProcessingAgreementDTO';

export function toDataProcessingAgreementRequestDTO(
  contractformData: Partial<{
    fullName: string;
    responsibleBody: string;
  }>,
): DataProcessingAgreementRequestDTO {
  if (!contractformData.fullName) {
    throw new Error();
  }
  const dataProcessingAgreementRequestDTO = new DataProcessingAgreementRequestDTO();
  dataProcessingAgreementRequestDTO.dataProcessingAgreement = {
    date: new Date().toISOString(),
    signatory: contractformData.fullName,
    responsibleBody: contractformData.responsibleBody,
  };
  return dataProcessingAgreementRequestDTO;
}
