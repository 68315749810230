<mat-menu
  #bulkActionMenu="matMenu"
  [overlapTrigger]="false"
  xPosition="before"
  yPosition="above"
  class="mat-menu-panel bulk-menu"
>
  @for (action of bulkActions; track action.label) {
    @if (selection$ | async; as selection) {
      <div class="px-4 text-sm font-medium">
        <button
          (click)="action.executeAction(selection.selected)"
          class="font-medium"
          mat-menu-item
        >
          <mat-icon class="material-icons-outlined pr-4">{{
            action.icon
          }}</mat-icon>
          {{ action.label }}
        </button>
      </div>
    }
  }
</mat-menu>

<mat-menu
  #filterActionMenu="matMenu"
  [overlapTrigger]="false"
  xPosition="before"
  yPosition="above"
  class="mat-menu-panel bulk-menu"
>
  @if (filterCategories) {
    <app-sep-filter
      [categories]="filterCategories"
      [applyButtonText]="'general.apply' | transloco"
      (filtersChanged)="applyFilter($event)"
    ></app-sep-filter>
  }
</mat-menu>

<mat-menu
  #rowActionMenu="matMenu"
  [overlapTrigger]="false"
  yPosition="above"
  xPosition="after"
  class="mat-menu-panel bulk-menu"
>
  @for (action of rowActions; track action.label) {
    @if (targetedRow) {
      <div class="px-6 text-xs">
        <button
          (click)="action.executeAction(targetedRow)"
          class="font-medium"
          mat-menu-item
          [disabled]="checkDisabled(action, targetedRow)"
        >
          <mat-icon class="material-icons-outlined pr-4">{{
            action.icon
          }}</mat-icon>
          {{ getLabel(action, targetedRow) }}
        </button>
      </div>
    }
  }
</mat-menu>

<!-- Table Heading -->
<div class="rounded-xl border border-grey-lightGrey shadow-sm">
  @if (name) {
    <h1 class="px-6 pb-1 pt-5 text-xl font-medium">{{ name }}</h1>
  }

  <div class="flex w-full justify-between p-2">
    <div class="flex items-center justify-center pl-3">
      @if (hasCheckbox) {
        <mat-checkbox
          class="text-xs"
          (change)="toggleAllRows()"
          [checked]="
            sepTableService.dataSource.length > 0 &&
            (selection$ | async)?.selected?.length ===
              sepTableService.dataSource.length
          "
        >
        </mat-checkbox>
        <div class="pl-2 text-xs">
          @if (checkboxSelectedText) {
            {{
              (selection$ | async)?.selected?.length +
                ' ' +
                checkboxSelectedText
            }}
          }
        </div>
      }
    </div>

    <div class="flex items-center justify-end space-x-2">
      @if (hasBulkActionButton && (selection$ | async)?.selected?.length) {
        <button
          class="ml-2 flex items-center justify-center rounded border border-grey-lightGrey p-1"
          [matMenuTriggerFor]="bulkActionMenu"
        >
          <mat-icon class="material-icons-outlined hvs-icon"
            >more_vert</mat-icon
          >
        </button>
      }
      @if (filterCategories && sepTableService.dataSource.length > 0) {
        <button
          class="ml-2 flex items-center justify-center rounded border border-grey-lightGrey p-1"
          [ngClass]="{
            'outline-none ring-2 ring-blue ring-opacity-50 shadow-sm':
              (sepTableService.isFiltered$ | async)
          }"
          [matMenuTriggerFor]="filterActionMenu"
        >
          <mat-icon
            class="material-icons-outlined hvs-icon"
            [ngClass]="{ 'text-blue': (sepTableService.isFiltered$ | async) }"
            >filter_alt</mat-icon
          >
        </button>
      }
      @if (allowExport) {
        <button
          class="ml-2 flex items-center justify-center rounded border border-grey-lightGrey p-1"
          (click)="exportContentButtonClicked()"
        >
          <mat-icon class="material-icons-outlined">download</mat-icon>
        </button>
      }
      @if (primaryButtonTitle) {
        <app-primary-button
          class="max-md:hidden"
          [text]="primaryButtonTitle"
          [type]="buttonType.LEADING_ADD_ICON"
          (buttonClicked)="primaryButtonClicked()"
        ></app-primary-button>
      }
    </div>
  </div>

  <div
    class="relative overflow-y-scroll"
    [ngClass]="{
      'max-h-[30vh]': type === sepTableType.SMALL,
      'max-h-[45vh]': type === sepTableType.LARGE
    }"
  >
    <table mat-table [dataSource]="sepTableService.dataView" class="w-full">
      <!-- Column Definition -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            class="text-xs"
            (click)="$event.stopPropagation()"
            (change)="selectRow(row)"
            [checked]="(selection$ | async)?.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Column Definition -->
      @for (column of columns; track column.headerName) {
        <ng-container matColumnDef="{{ column.headerName }}">
          <th
            class="cursor-pointer"
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="{ 'text-transparent text-xs ': hideColumnHeader }"
            (click)="sortColumn(column.key)"
          >
            <span class="flex items-center">
              {{ column.headerName }}
              <mat-icon class="hvs-icon p-1 text-xs">swap_vert</mat-icon>
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
            @if (column.field) {
              {{ element[column.field] }}
            } @else if (column.renderComponent) {
              <ng-container
                [ngTemplateOutlet]="column.renderComponent"
                [ngTemplateOutletContext]="{ $implicit: element }"
              ></ng-container>
            }
          </td>
        </ng-container>
      }

      <!-- Column Definition -->
      <ng-container matColumnDef="rowActions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            class="ml-2 min-w-0"
            [matMenuTriggerFor]="rowActionMenu"
            (click)="setTargetedRow(row)"
          >
            <mat-icon
              class="material-icons-outlined cursor-pointer text-base text-grey"
              >more_vert</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <!-- Table Header Definition -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        [ngStyle]="{
          height: hideColumnHeader ? '15px' : '',
          'background-color': '#f7f8f9'
        }"
      ></tr>
      <!-- Table Row Definition -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- No Data Row Definition -->
      <tr class="mat-mdc-row mdc-data-table__row" *matNoDataRow>
        <td
          class="mat-mdc-cell mdc-data-table__cell h-20 text-center"
          colspan="1000"
          style="text-align: center"
        >
          {{ noDataText }}
        </td>
      </tr>
    </table>
  </div>
</div>
