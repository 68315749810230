import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragOver]',
  standalone: true,
})
export class DragOverDirective {
  @HostBinding('class.bg-gray-100') isHoveringBg = false;
  @HostBinding('class.shadow-md') isHoveringShadow = false;

  constructor() {}

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.isHoveringBg = true;
    this.isHoveringShadow = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.isHoveringBg = false;
    this.isHoveringShadow = false;
  }

  @HostListener('drop', ['$event']) onDragEnd(): void {
    this.isHoveringBg = false;
    this.isHoveringShadow = false;
  }
}
