<div class="w-full" *transloco="let t">
  <form class="flex h-20" [formGroup]="userForm" role="form">
    <app-text-input
      class="mr-2 w-1/4 grow"
      [control]="userForm.controls.firstName"
      [controlName]="'firstName'"
      [placeholder]="t('portal.users.table.editUserDialog.fistnamePlaceholder')"
    />
    <app-text-input
      class="mr-2 w-1/4 grow"
      [control]="userForm.controls.lastName"
      [controlName]="'lastName'"
      [placeholder]="t('portal.users.table.editUserDialog.lastnamePlaceholder')"
    />
    <app-text-input
      class="w-1/2 grow"
      [control]="userForm.controls.email"
      [controlName]="'email'"
      [placeholder]="t('portal.users.table.editUserDialog.emailPlaceholder')"
    />
  </form>
</div>
