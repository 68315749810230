import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
  providedIn: 'root',
})
export class SepTableSelectionService<T> {
  private readonly selectionSubject: BehaviorSubject<SelectionModel<T>> = new BehaviorSubject(
    new SelectionModel<T>(true, []),
  );

  selection$: Observable<SelectionModel<T>> = this.selectionSubject.asObservable();

  selectRow(row: T) {
    const currentSelection = this.selectionSubject.getValue();
    currentSelection.toggle(row);
    this.selectionSubject.next(currentSelection);
  }

  toggleAllRows(dataSource: T[]) {
    const currentSelection = this.selectionSubject.getValue();
    if (currentSelection.selected.length === dataSource.length) {
      currentSelection.clear();
    } else {
      dataSource.forEach((row) => currentSelection.select(row));
    }
    this.selectionSubject.next(currentSelection);
  }

  clearSelection() {
    const currentSelection = this.selectionSubject.getValue();
    currentSelection.clear();
    this.selectionSubject.next(currentSelection);
  }
}
