import { HttpErrorResponse } from '@angular/common/http';
import { CustomPortalError } from '../models/CustomPortalError';
import { ErrorType } from '../models/ErrorType';
import { toGeneralApiErrorType } from './api-error-type-mapper';
import { isProblemJson, ProblemJson } from '../models/ProblemJson';

function getErrorDetails(error: HttpErrorResponse) {
  let errorDetails: ProblemJson | undefined;
  if (isProblemJson(error.error)) {
    errorDetails = error.error;
  }
  return errorDetails;
}

export function mapToCustomPortalError(error: HttpErrorResponse): CustomPortalError {
  const errorDetails = getErrorDetails(error);
  const portalError = new CustomPortalError(error.message, errorDetails);
  if (error.error instanceof ErrorEvent) {
    portalError.errorType = ErrorType.CLIENT_SIDE_ERROR;
  } else {
    portalError.errorType = toGeneralApiErrorType(error.status, errorDetails);
  }
  return portalError;
}
