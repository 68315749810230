<div class="w-full">
  @for (category of categories; track category) {
    <button
      (click)="toggleCategory(category.label); $event.stopPropagation()"
      class="flex w-full items-center rounded-lg px-4 text-left"
    >
      <span
        class="material-icons-outlined"
        [class.rotate-180]="!isOpen(category.label)"
      >
        expand_more
      </span>
      <span class="text-sm font-semibold">{{ category.label }}</span>
    </button>
    @if (isOpen(category.label)) {
      <div class="mt-2 bg-background-grey pl-3">
        @for (option of category.options; track option) {
          <div class="flex items-center space-x-2 px-2 py-1">
            <mat-checkbox
              class="custom-mdc-checkbox"
              [checked]="option.selected"
              (change)="toggleOptionSelection(option)"
              (click)="$event.stopPropagation()"
            />

            <span class="text-sm font-normal">{{ option.label }}</span>
          </div>
        }
      </div>
    }
  }
  <div class="w-36 px-4 pt-2">
    <app-primary-button
      [text]="applyButtonText"
      (buttonClicked)="applyFilters()"
    ></app-primary-button>
  </div>
</div>
