import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserCreationNotificationService {
  private readonly userChangedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getUserChangedNotification(): Observable<boolean> {
    return this.userChangedSubject$.asObservable();
  }

  setUserChangedNotification(): void {
    this.userChangedSubject$.next(true);
  }
}
