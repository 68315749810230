import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogModel } from '../../../../shared/components/dialog/dialog';
import { LanguageService } from '../../../../core/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
  ) {}

  showConfirmationDialog(onConfirm: () => void) {
    const upgradeConfirmationDialog = new DialogModel(this.dialog);
    upgradeConfirmationDialog.closable = true;
    upgradeConfirmationDialog.headline = this.languageService.translateOnRuntime(
      'portal.settings.billingSettings.upgradeConfirmationDialog.headline',
    );
    upgradeConfirmationDialog.description = this.languageService.translateOnRuntime(
      'portal.settings.billingSettings.upgradeConfirmationDialog.description',
    );

    upgradeConfirmationDialog.buttonRowData = {
      confirmButtonText: this.languageService.translateOnRuntime('portal.settings.billingSettings.submitButton'),
      confirmButtonAction: onConfirm,
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };

    upgradeConfirmationDialog.openDialog();

    return upgradeConfirmationDialog;
  }
}
