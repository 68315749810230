<ng-template #checkboxLabel>
  <div *transloco="let t">
    {{
      t('portal.settings.dataProcessingContract.contractAgreementDescription')
    }}
  </div>
</ng-template>

<div class="mt-3" *transloco="let t">
  <form [formGroup]="contractForm">
    <h2 class="mb-5 text-xl font-semibold">
      {{ t('portal.settings.dataProcessingContract.headline') }}
    </h2>
    <p class="mb-5 text-sm font-normal text-primary">
      {{ t('portal.settings.dataProcessingContract.description') }}
    </p>
    <div class="mb-4">
      <button
        mat-button
        class="mb-2 flex cursor-pointer items-center text-sm text-blue hover:underline"
        (click)="download('DataProcessingAgreement')"
      >
        <mat-icon class="material-icons-outlined mr-2">cloud_download</mat-icon>
        {{
          t(
            'portal.settings.dataProcessingContract.contractDownloadDescription'
          )
        }}
      </button>
      <button
        mat-button
        class="mb-2 flex cursor-pointer items-center text-sm text-blue hover:underline"
        (click)="download('TechnicalAndOrganizationalMeasures')"
      >
        <mat-icon class="material-icons-outlined mr-2">cloud_download</mat-icon>
        {{
          t(
            'portal.settings.dataProcessingContract.attachmentDownloadDescription'
          )
        }}
      </button>
    </div>

    <div class="mb-4 flex items-start">
      <app-checkbox
        [control]="contractForm.controls.agreement"
        [controlName]="'agreement'"
        [label]="checkboxLabel"
      ></app-checkbox>
    </div>

    <div
      class="mb-4 w-28 rounded-lg border border-grey-midGrey bg-grey-lightGrey"
    >
      <app-text-input
        [control]="contractForm.controls.date"
        [controlName]="'date'"
        readonly
      ></app-text-input>
    </div>

    <div class="mb-4">
      <label class="mb-1 block text-sm font-medium text-gray-700" for="name">
        {{ t('portal.settings.dataProcessingContract.form.fullNameLabel') }}
      </label>
      <app-text-input
        [control]="contractForm.controls.fullName"
        [placeholder]="
          t('portal.settings.dataProcessingContract.form.fullNamePlaceholder')
        "
        [controlName]="'fullName'"
      ></app-text-input>
    </div>

    <div class="mb-6">
      <label
        class="mb-1 block text-sm font-medium text-gray-700"
        for="responsible"
      >
        {{ t('portal.settings.dataProcessingContract.form.legalEntityLabel') }}
      </label>
      <app-text-input
        [control]="contractForm.controls.responsibleBody"
        [placeholder]="
          t(
            'portal.settings.dataProcessingContract.form.legalEntityPlaceholder'
          )
        "
        [controlName]="'responsibleEntity'"
      ></app-text-input>
    </div>

    <div class="w-32">
      <app-primary-button
        [text]="t('portal.settings.dataProcessingContract.form.submitButton')"
        [loading]="isLoading"
        [disabled]="!contractForm.valid || contractForm.disabled"
        (buttonClicked)="onSubmit()"
      ></app-primary-button>
    </div>
  </form>
</div>
