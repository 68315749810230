<div class="ml-8 mt-20" *transloco="let t" @easeInFast>
  <h1 class="text-3xl font-semibold">
    {{ t('portal.courses.overview.headline') }}
  </h1>
  @if (subscription$ | async; as subscription) {
    @if (subscription.isBoundToLearningPlans) {
      Learning Plans
    } @else {
      <app-course-instances-overview></app-course-instances-overview>
    }
  }
</div>
