<div *transloco="let t" class="flex h-full flex-col">
  <header class="mt-5 pl-6">
    <p class="mb-3 text-lg font-semibold">
      {{ t('portal.courses.enrollment.addParticipantsHeadline') }}
    </p>
  </header>

  <div class="min-h-80 w-full flex-1 px-6 py-2">
    @if (studentsEligibleForEnrollment) {
      <app-course-enrollment-table
        [students]="studentsEligibleForEnrollment"
        (primaryButtonClickEvent)="handleUserCreationButtonClick()"
      ></app-course-enrollment-table>
      <div class="mt-4 flex flex-col">
        <span class="text-lg font-semibold">{{
          t('portal.courses.enrollment.reminder.title')
        }}</span>
        <span class="mt-2 text-sm font-medium">{{
          t('portal.courses.enrollment.reminder.description')
        }}</span>
      </div>
      <div class="mb-2 mt-3">
        @if (canEnroll) {
          <app-license-info-card
            @easeIn
            [type]="selectedStudents.length ? 'enrollment' : 'basic'"
            [enrollmentDescription]="getEnrollmentDescription()"
          ></app-license-info-card>
        } @else {
          <app-info-card
            @easeIn
            [headline]="
              t(
                'portal.courses.enrollment.licenses.numberOfAvailableLicensesExceededHeadline',
                { numberOfAvailableLicenses: numberOfLicenses$ | async }
              )
            "
            [description]="
              t(
                'portal.courses.enrollment.licenses.numberOfAvailableLicensesExceededDescription'
              )
            "
            ><app-text-with-link
              [leadingText]="
                t('portal.courses.enrollment.licenses.requestLicensesEmail')
              "
              [trailingText]="
                t(
                  'portal.courses.enrollment.licenses.requestLicensesEmailEnding'
                )
              "
              [email]="supportEmail"
          /></app-info-card>
        }
      </div>
    } @else {
      <div
        class="absolute left-1/2 top-1/2 size-8 -translate-x-1/2 -translate-y-1/2"
      >
        <app-loading />
      </div>
    }
  </div>

  <footer class="w-full border-t-2 border-grey-lightGrey px-4 py-1">
    <app-button-row
      [primaryButtonText]="t('portal.courses.enrollment.enrollButtonText')"
      (primaryButtonClickedEvent)="enrollStudents()"
      [disablePrimaryButton]="selectedStudents.length === 0 || !canEnroll"
      [isLoading]="isLoading"
      [secondaryButtonText]="t('general.abort')"
      (secondaryButtonClickedEvent)="handleAbortButtonClick()"
    ></app-button-row>
  </footer>
</div>
