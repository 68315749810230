import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.services';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslationLoader {
  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly transloco: TranslocoService,
  ) {}

  normalizeLangCode(lang: string): string {
    return lang.split('-')[0]; // Take only the primary language subtag
  }

  /**
   *
   * This function makes sure that languages are loaded before app initializes.
   * It is mostly important to be able to use the transloco.translate() function in components.
   * Without this preload, the .translate() function might return "missing translation key" error,
   * however it does not affect template binding translations, such as the async pipe | transloco.
   *
   */
  async preloadTranslations() {
    const availableLangs = this.transloco.getAvailableLangs() as string[];
    const localStorageLang = this.localStorage.getItem('lang');
    if (localStorageLang && availableLangs.includes(localStorageLang)) {
      return this.loadAndSetLanguage([localStorageLang]);
    }
    const langMatches = navigator.languages.map(this.normalizeLangCode).filter((lang) => availableLangs.includes(lang));
    return this.loadAndSetLanguage(langMatches);
  }

  private async loadAndSetLanguage(langMatches: string[]) {
    return firstValueFrom(this.transloco.load(langMatches[0]))
      .then((lang) => {
        this.transloco.setActiveLang(langMatches[0]);
        return lang;
      })
      .catch((e) => {
        console.error(e);
      });
  }
}
