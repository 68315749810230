<ng-template #personCard let-element>
  <div class="m-4 flex flex-row items-center">
    <div class="mr-1 w-5/6">
      <app-user-card
        [username]="element.username"
        [email]="element.email"
        [highlightUsername]="false"
      />
    </div>
    <div class="flex w-3/4 pl-3" *transloco="let t">
      @if (!element.license) {
        <app-badge
          [text]="
            t('portal.courses.enrollment.licenses.licenseAssignmentBadge')
          "
          type="warning"
        />
      } @else {
        <div
          class="flex size-5 cursor-pointer items-center justify-center rounded-full bg-grey-lightGrey leading-none"
        >
          <mat-icon
            class="p-0.5 text-sm text-grey"
            [matTooltip]="
              t('portal.courses.enrollment.licenses.licenseAssignedText', {
                name: element.username
              })
            "
            matTooltipPosition="above"
            matTooltipClass="tooltip-above"
            >check</mat-icon
          >
        </div>
      }
    </div>
  </div>
</ng-template>

<app-sep-table
  *transloco="let t"
  [columns]="columns"
  [dataSource]="students"
  [hideColumnHeader]="true"
  (primaryButtonClickedEvent)="primaryButtonClick()"
  [primaryButtonTitle]="t('portal.users.creation.createText')"
  [noDataText]="t('portal.users.creation.noDataTableText')"
  [checkboxSelectedText]="t('portal.courses.enrollment.checkboxSelectedText')"
  [type]="sepTableType.SMALL"
></app-sep-table>
