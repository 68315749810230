export interface ProblemJson {
  type: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
}

export function isProblemJson(error: unknown): error is ProblemJson {
  return (
    typeof error === 'object' &&
    error !== null &&
    typeof (error as ProblemJson).type === 'string' &&
    (typeof (error as ProblemJson).title === 'undefined' || typeof (error as ProblemJson).title === 'string') &&
    (typeof (error as ProblemJson).status === 'undefined' || typeof (error as ProblemJson).status === 'number') &&
    (typeof (error as ProblemJson).detail === 'undefined' || typeof (error as ProblemJson).detail === 'string') &&
    (typeof (error as ProblemJson).instance === 'undefined' || typeof (error as ProblemJson).instance === 'string')
  );
}
