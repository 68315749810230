import { ErrorType } from '../models/ErrorType';
import { ProblemJson } from '../models/ProblemJson';

function extractExceptionType(errorTypeUrl?: string): ErrorType {
  if (!errorTypeUrl) {
    return ErrorType.BAD_REQUEST;
  }

  const errorTypeUrlParts = errorTypeUrl.split('/');
  const exceptionType = errorTypeUrlParts[errorTypeUrlParts.length - 1];
  switch (exceptionType) {
    case 'ValidationException':
      return ErrorType.VALIDATION_EXCEPTION;
    case 'NotEnoughLicensesException':
      return ErrorType.NOT_ENOUGH_LICENSES_AVAILABLE;
    case 'LicenseAlreadyConsumedException':
      return ErrorType.LICENSE_ALREADY_CONSUMED;
    case 'PaymentFailedException':
      return ErrorType.PAYMENT_FAILED;
    default:
      return ErrorType.BAD_REQUEST;
  }
}

export function toGeneralApiErrorType(status: number, errorDetails?: ProblemJson): ErrorType {
  switch (status) {
    case 400:
      return extractExceptionType(errorDetails?.type);
    case 401:
      return ErrorType.UNAUTHORIZED;
    case 403:
      return ErrorType.FORBIDDEN;
    case 404:
      return ErrorType.NOT_FOUND;
    case 500:
      return ErrorType.INTERNAL_SERVER_ERROR;
    case 503:
      return ErrorType.THIRD_PARTY_SERVICE_UNAVAILABLE;
    default:
      return ErrorType.UNKNOWN_ERROR;
  }
}
