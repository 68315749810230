import { ChangeDetectorRef, EventEmitter, Directive, AfterViewInit } from '@angular/core';
import { Column } from '../../../shared/components/sep-table/Column';
import { LanguageService } from '../../../core/services/language.service';
import { StudentEligibleForEnrollmentBaseUIModel } from './EnrolledStudentBaseUIModel';

@Directive()
export abstract class EnrollmentTableBaseComponent implements AfterViewInit {
  columns: Array<Column<StudentEligibleForEnrollmentBaseUIModel, string | { $implicit: string }>> = [
    {
      headerName: this.getTranslation('nameAndEmail'),
      renderComponent: undefined,
      key: 'username',
    },
  ];
  abstract students: StudentEligibleForEnrollmentBaseUIModel[];
  abstract primaryButtonClickEvent: EventEmitter<void>;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly languageService: LanguageService,
  ) {}

  ngAfterViewInit(): void {
    this.configureColumns();
    this.cdr.detectChanges();
  }

  primaryButtonClick(): void {
    this.primaryButtonClickEvent.emit();
  }

  protected getTranslation(key: string): string {
    return this.languageService.translateOnRuntime('portal.users.table.header.' + key);
  }

  protected abstract configureColumns(): void;
}
