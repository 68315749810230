<div class="w-96 rounded-lg border shadow-sm" *transloco="let t">
  <div class="flex justify-end px-4 py-3">
    <app-badge
      [text]="
        courseInstance.numberOfEnrolledStudents === 1
          ? t('portal.courses.overview.participant')
          : t('portal.courses.overview.participants', {
              number: courseInstance.numberOfEnrolledStudents
            })
      "
      [type]="'warning'"
      [icon]="
        courseInstance.numberOfEnrolledStudents === 0 ? 'warning' : undefined
      "
    ></app-badge>
  </div>

  <div class="flex items-center space-x-4 pb-5 pl-6">
    <div class="size-24">
      <app-doughnut-chart
        [percentage]="getCompletionPercentage()"
      ></app-doughnut-chart>
    </div>

    <div class="p-4">
      <p class="text-4xl font-semibold text-primary">
        {{ getCompletionPercentage() + '%' }}
      </p>
      <p class="text-sm text-grey">
        {{ t('portal.courses.overview.courseCompletionRate') }}
      </p>
      <p class="text-sm text-grey">
        {{ courseInstance.startDate | date: 'dd.MM.yyyy' }} -
        {{ courseInstance.endDate | date: 'dd.MM.yyyy' }}
      </p>
    </div>
  </div>

  <div
    class="flex items-center justify-between rounded-b-lg border-t bg-background-grey px-5 py-4"
  >
    <p class="text-sm font-semibold text-primary">
      {{ t('portal.courses.courseTitleKeys.' + courseInstance.course.name) }}
    </p>

    <div class="h-10 w-48">
      <app-primary-button
        [text]="t('portal.courses.overview.setupCourseButton')"
        (buttonClicked)="goToCourseDetails(courseInstance.id)"
      />
    </div>
  </div>
</div>
