import { Component, Input, OnChanges } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './doughnut-chart.component.html',
  styleUrl: './doughnut-chart.component.css',
})
export class DoughnutChartComponent implements OnChanges {
  @Input({ required: true }) percentage = 0;

  doughnutChartData: ChartData<'doughnut'>;
  doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    cutout: '65%',
    devicePixelRatio: 4,
    events: [],
  };

  ngOnChanges() {
    this.updateChart();
  }

  private updateChart() {
    this.doughnutChartData = {
      datasets: [
        {
          data: [this.percentage, 100 - this.percentage],
          backgroundColor: ['#D0D5DD', '#EAECF0'],
        },
      ],
    };
  }
}
