import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CourseDataService {
  private courseId: string | undefined = undefined;
  private courseName: string | undefined = undefined;

  constructor() {}

  getCourseId(): string | undefined {
    return this.courseId;
  }

  setCourseId(id: string): void {
    this.courseId = id;
  }

  setCourseName(name: string) {
    this.courseName = name;
  }

  getCourseName(): string | undefined {
    return this.courseName;
  }
}
