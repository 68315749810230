<div class="mt-2 flex flex-row flex-wrap">
  @if ((courseInstancesRes$ | async)?.courseInstances; as courseInstances) {
    @for (courseInstance of courseInstances; track courseInstance.id) {
      <div class="mr-8 mt-6" @easeInFast>
        <app-course-instance-info-card
          [courseInstance]="courseInstance"
        ></app-course-instance-info-card>
      </div>
    }
  }
</div>
