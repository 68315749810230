export class DataProcessingAgreementResponseDTO {
  dataProcessingAgreement: {
    date: string;
    signatory: string;
    responsibleBody?: string;
  };
}

export class DataProcessingAgreementRequestDTO {
  dataProcessingAgreement: {
    date: string;
    signatory: string;
    responsibleBody?: string;
  };
}
