<div
  class="rounded-lg border border-grey-lightGrey text-sm shadow-sm"
  *transloco="let t"
>
  <div class="flex border-b border-gray-200 py-5">
    <span class="flex-1 pl-4">{{
      t('portal.courses.analytics.courseStatistics.registeredStudents')
    }}</span>
    <span class="pr-4 text-grey">{{ numberOfEnrolledStudents }}</span>
  </div>
  <div class="flex border-b border-gray-200 py-5">
    <span class="flex-1 pl-4">{{
      t('portal.courses.analytics.courseStatistics.courseStartedStudents')
    }}</span>
    <span class="pr-4 text-grey"
      >{{ numberOfStudentsCourseStarted }} ({{
        numberOfEnrolledStudents === 0
          ? '0'
          : ((numberOfStudentsCourseStarted / numberOfEnrolledStudents) * 100
            | number: '1.0-2')
      }}%)</span
    >
  </div>
  <div class="flex py-5">
    <span class="flex-1 pl-4">{{
      t('portal.courses.analytics.courseStatistics.courseCompletedStudents')
    }}</span>
    <span class="pr-4 text-grey"
      >{{ numberOfStudentsCourseCompleted }} ({{
        numberOfEnrolledStudents === 0
          ? '0'
          : ((numberOfStudentsCourseCompleted / numberOfEnrolledStudents) * 100
            | number: '1.0-2')
      }}%)</span
    >
  </div>
</div>
