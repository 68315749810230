import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter, switchMap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthService } from '../services/auth.service';

export const roleGuard: CanActivateFn = (): Observable<boolean> => {
  const authService = inject(AuthService);
  const msalBroadcastService = inject(MsalBroadcastService);
  const router = inject(Router);

  const subject: Subject<boolean> = new Subject();

  msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(async () => {
        try {
          const hasRouteAccess = await authService.isSepAdmin();
          if (!hasRouteAccess) {
            void router.navigate(['/']);
          }
          subject.next(hasRouteAccess);
        } catch (error) {
          console.error('Error acquiring route access right', error);
          subject.next(false);
        }
      }),
    )
    .subscribe();

  return subject.asObservable();
};
