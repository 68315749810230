import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserUIModel } from '../../models/UserUIModel';
import { TextInputComponent } from '../../../../../shared/components/text-input/text-input.component';
import {
  emailValidationPattern,
  forbiddenCharactersValidator,
} from '../../../../../shared/validators/custom-validators';
import { Observable } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';

export interface UserFormControls {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
}

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, TextInputComponent, TranslocoDirective],
})
export class EditUserFormComponent implements OnInit {
  @Input({ required: true }) userData: UserUIModel;
  userForm!: FormGroup<UserFormControls>;
  @Output() formStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.observeFormValidityAndEmit();
  }

  private initForm() {
    this.userForm = this.fb.group<UserFormControls>({
      firstName: this.fb.nonNullable.control(this.userData.firstName, [
        Validators.required,
        Validators.maxLength(50),
        forbiddenCharactersValidator(),
      ]),
      lastName: this.fb.nonNullable.control(this.userData.lastName, [
        Validators.required,
        Validators.maxLength(50),
        forbiddenCharactersValidator(),
      ]),
      email: this.fb.nonNullable.control(this.userData.email, [
        Validators.required,
        Validators.pattern(emailValidationPattern),
        forbiddenCharactersValidator(),
      ]),
    });
  }

  observeFormValidityAndEmit() {
    this.formStatusChanged.emit(this.userForm.valid);
    this.userForm.statusChanges.subscribe(() => {
      this.formStatusChanged.emit(this.userForm.valid);
    });
  }

  getFormValues(): Partial<UserUIModel> {
    return this.userForm.value;
  }

  get formValueChanges$(): Observable<
    Partial<{
      firstName: string;
      lastName: string;
      email: string;
    }>
  > {
    return this.userForm.valueChanges;
  }

  get formControls() {
    return this.userForm.controls;
  }

  get isFormValid() {
    return this.userForm.valid;
  }
}
