import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LearningPlanDataService } from '../../../domains/academy/learning-plan/services/learning-plan-data.service';
import { LearningPlanEnrolledStudentTableComponent } from '../../../domains/academy/learning-plan/components/learning-plan-enrolled-student-table/learning-plan-enrolled-student-table.component';
import { LearningPlanService } from '../../../domains/academy/learning-plan/services/learning-plan.service';
import { CustomPortalError } from '../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { AsyncPipe } from '@angular/common';
import { LearningPlanInstanceDTO } from '../../../domains/academy/learning-plan/models/LearningPlanDTO';

@Component({
  selector: 'app-learning-plan-detail',
  standalone: true,
  imports: [
    MatTabsModule,
    TranslocoDirective,
    MatIcon,
    RouterOutlet,
    LearningPlanEnrolledStudentTableComponent,
    AsyncPipe,
  ],
  templateUrl: './learning-plan-detail.component.html',
  styleUrl: './learning-plan-detail.component.css',
})
export class LearningPlanDetailComponent implements OnInit {
  private readonly destroy$: Subject<void> = new Subject();
  learningPlanInstanceDetails$: BehaviorSubject<LearningPlanInstanceDTO | null> =
    new BehaviorSubject<LearningPlanInstanceDTO | null>(null);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly learningPlanDataService: LearningPlanDataService,
    private readonly learningPlanService: LearningPlanService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.observeLearningPlanInstanceIdInUrlAndFetch();
  }

  private observeLearningPlanInstanceIdInUrlAndFetch(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.learningPlanDataService.setLearningPlanInstanceId(id);
        this.getLearningPlanDetails(id);
        //fetch learning plan details and adjust name
        this.learningPlanDataService.setLearningPlaneName('Compliance');
      }
    });
  }

  getLearningPlanDetails(id: string) {
    this.learningPlanService.getLearningPlanInstanceDetails(id).subscribe({
      next: (res) => {
        this.learningPlanInstanceDetails$.next(res.learningPlanInstance);
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  showLearningPlanEnrollmentDialog() {
    void this.router.navigate(['dialog'], { relativeTo: this.route, queryParams: { step: 'enroll' } });
  }
}
