import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-info-card',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './info-card.component.html',
})
export class InfoCardComponent {
  @Input({ required: true }) headline: string;
  @Input({ required: true }) description: string;
}
