import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { SepTableComponent } from '../../../../../shared/components/sep-table/sep-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCardComponent } from '../../../../../shared/components/user-card/user-card.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { LanguageService } from '../../../../../core/services/language.service';
import { Column } from '../../../../../shared/components/sep-table/Column';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';
import { EnrolledStudentTableBaseComponent } from '../../../base/EnrolledStudentTableBaseComponent';
import { LearningPlanEnrolledStudentUIModel } from '../../models/LearningPlanEnrolledStudentUIModel';
import { LearningPlanService } from '../../services/learning-plan.service';
import { LearningPlanDataService } from '../../services/learning-plan-data.service';
import { toLearningPlanEnrolledStudentUIModel } from '../../mappers/dto-mappers';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { TableBulkAction, TableRowAction } from '../../../../../shared/components/sep-table/Action';
import { FilterCategory } from '../../../../../shared/components/sep-filter/sep-filter.component';
import { EnrollmentNotificationService } from '../../../../../core/services/enrollment-notification.service';

@Component({
  selector: 'app-learning-plan-enrolled-student-table',
  standalone: true,
  imports: [SepTableComponent, UserCardComponent, TranslocoDirective],
  templateUrl: './learning-plan-enrolled-student-table.component.html',
  styleUrl: './learning-plan-enrolled-student-table.component.css',
})
export class LearningPlanEnrolledStudentTableComponent
  extends EnrolledStudentTableBaseComponent
  implements AfterViewInit, OnInit
{
  @ViewChild('studentCard', { static: true }) studentCard!: TemplateRef<{ $implicit: string }>;
  dataSource: LearningPlanEnrolledStudentUIModel[] = [];
  students: LearningPlanEnrolledStudentUIModel[] | undefined;
  filterCategories: FilterCategory[] | undefined;

  columns: Array<Column<LearningPlanEnrolledStudentUIModel, string | { $implicit: string }>> = [
    {
      headerName: this.getTranslation('learningPlans.enrolledStudentTable.headers.nameAndEmail'),
      renderComponent: undefined,
      key: 'username',
    },
    {
      headerName: this.getTranslation('learningPlans.enrolledStudentTable.headers.currentPhase'),
      field: 'currentPhase',
      key: 'currentPhase',
    },
    {
      headerName: this.getTranslation('learningPlans.enrolledStudentTable.headers.status'),
      field: 'status',
      key: 'status',
    },
  ];

  bulkActions: Array<TableBulkAction<LearningPlanEnrolledStudentUIModel>> = [
    {
      label: this.getTranslation('courses.unenrollment.bulkUnenrollButtonText'),
      icon: 'group_remove',
      executeAction: (targetedStudents: LearningPlanEnrolledStudentUIModel[]) => {
        this.showUnenrollmentConfirmationDialog(targetedStudents, () => {
          this.unenrollStudents(targetedStudents);
        });
      },
    },
    {
      label: this.getTranslation('courses.reminders.bulkSendReminder'),
      icon: 'send',
      executeAction: (targetedStudents: LearningPlanEnrolledStudentUIModel[]) => {
        this.showSendReminderConfirmationDialog(targetedStudents, () => {
          this.sendReminder(targetedStudents);
        });
      },
    },
  ];

  rowActions: Array<TableRowAction<LearningPlanEnrolledStudentUIModel>> = [
    {
      label: this.getTranslation('courses.unenrollment.unenrollButtonText'),
      icon: 'person_remove',
      executeAction: (targetedStudent: LearningPlanEnrolledStudentUIModel) => {
        this.showUnenrollmentConfirmationDialog([targetedStudent], () => {
          this.unenrollStudents([targetedStudent]);
        });
      },
    },
    {
      label: this.getTranslation('courses.reminders.sendReminder'),
      icon: 'send',
      executeAction: (targetedStudent: LearningPlanEnrolledStudentUIModel) => {
        this.showSendReminderConfirmationDialog([targetedStudent], () => {
          this.sendReminder([targetedStudent]);
        });
      },
    },
  ];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly learningPlanService: LearningPlanService,
    private readonly learningPlanDataService: LearningPlanDataService,
    private readonly snackbarService: SnackbarService,
    private readonly enrollmentNotificationService: EnrollmentNotificationService,
    router: Router,
    activatedRoute: ActivatedRoute,
    languageService: LanguageService,
    analyticsService: AnalyticsService,
    dialog: MatDialog,
  ) {
    super(dialog, languageService, analyticsService, router, activatedRoute);
  }

  //observe enrollment change events
  ngOnInit(): void {
    const learningPlanId = this.learningPlanDataService.getLearningPlanInstanceId();
    if (learningPlanId) {
      this.learningPlanService.getLearningPlanEnrollments(learningPlanId).subscribe({
        next: (response) => {
          this.students = toLearningPlanEnrolledStudentUIModel(response.students);
          this.dataSource = this.translateProperties(this.students);
        },
        error: (err: CustomPortalError) => {
          this.snackbarService.error(err.errorType);
        },
      });
    }
    this.initializeLearningPlanStatusFilter();
  }

  private sendReminder(students: LearningPlanEnrolledStudentUIModel[]) {
    this.learningPlanService.sendReminder(students).subscribe({
      next: () => {
        //analytics
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  private initializeLearningPlanStatusFilter(): void {
    let uniqueLearningPlanStatusValues: string[] = [];
    if (this.students) {
      uniqueLearningPlanStatusValues = Array.from(new Set(this.students.map((student) => student.status)));
    }

    if (uniqueLearningPlanStatusValues.length <= 1) {
      return;
    }

    this.filterCategories = [
      {
        label: this.getTranslation('learningPlans.enrolledStudentTable.headers.status'),
        value: 'status',
        options: uniqueLearningPlanStatusValues.map((value) => ({
          label: this.getTranslation('courses.analytics.courseProgress.' + value),
          selected: true,
        })),
      },
    ];
  }

  translateProperties(students: LearningPlanEnrolledStudentUIModel[]): LearningPlanEnrolledStudentUIModel[] {
    return students.map((student) => ({
      ...student,
      status: this.getTranslation('courses.analytics.courseProgress.' + student.status),
      currentPhase: student.currentPhase, //add translation
    }));
  }

  private unenrollStudents(students: LearningPlanEnrolledStudentUIModel[]) {
    const learningPlanId = this.learningPlanDataService.getLearningPlanInstanceId();
    if (learningPlanId) {
      this.learningPlanService.unenrollStudentsFromLearningPlan(students, learningPlanId).subscribe({
        next: () => {
          this.enrollmentNotificationService.setEnrollmentChanged();
        },
        error: (error: CustomPortalError) => {
          this.snackbarService.error(error.errorType);
        },
      });
    }
  }

  ngAfterViewInit(): void {
    this.columns[0].renderComponent = this.studentCard;
    this.cdr.detectChanges();
  }
}
