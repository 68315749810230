<div class="mx-8 mt-12" *transloco="let t">
  <h1 class="mb-8 text-3xl font-semibold">
    {{ t('portal.settings.settingsHeadline') }}
  </h1>
  <mat-tab-group class="remove-border-bottom">
    <mat-tab [label]="t('portal.settings.tabs.employees')">
      <div class="mt-5">
        <div class="w-5/6">
          <app-user-management-overview></app-user-management-overview>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="t('portal.settings.tabs.generalSettings')">
      <div class="mt-5">
        <app-user-settings />
      </div>
    </mat-tab>
    <mat-tab [label]="t('portal.settings.tabs.billing')">
      <div class="mt-5">
        <app-subscription-settings />
      </div>
    </mat-tab>
    <mat-tab [label]="t('portal.settings.tabs.dataProcessing')">
      <div class="mt-5">
        <div class="w-9/12">
          <app-data-processing-agreement />
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <router-outlet></router-outlet>
</div>
