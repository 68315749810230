<div class="mx-8 mt-12" *transloco="let t">
  <div class="flex items-center justify-between">
    <!--Replace placeholder headline with actual name of the learning plan-->
    <h1 class="mb-8 text-3xl font-semibold">Datenschutz Grundlage</h1>
    <button
      mat-icon-button
      class="flex items-center rounded-md bg-blue p-2 hover:bg-blue-hover"
      (click)="showLearningPlanEnrollmentDialog()"
    >
      <mat-icon class="text-base text-white">add</mat-icon>
      <mat-icon class="text-white">person</mat-icon>
    </button>
  </div>
  <mat-tab-group class="remove-border-bottom">
    <mat-tab [label]="'Übersicht'">
      <div class="mt-5">
        <div class="w-5/6">Learning Plan Overview</div>
      </div>
    </mat-tab>

    <mat-tab [label]="'Teilnehmer'">
      <div class="mt-5">
        <app-learning-plan-enrolled-student-table />
      </div>
    </mat-tab>
  </mat-tab-group>
  <!--router outlet for route aware dialog-->
  <router-outlet></router-outlet>
</div>
