import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

export interface Column<C> {
  headerName: string;
  field?: string;
  renderComponent?: TemplateRef<C>;
}

@Component({
  selector: 'app-simple-table',
  standalone: true,
  imports: [CommonModule, MatTableModule],
  templateUrl: './simple-table.component.html',
  styleUrl: './simple-table.component.css',
})
export class SimpleTableComponent<T> implements OnChanges {
  @Input() columns: Array<Column<string | { $implicit: string }>> = [];
  @Input() dataSource: T[] = [];
  dataView: T[] = [];

  ngOnChanges(changes?: SimpleChanges): void {
    const val = changes?.['dataSource'] ? (changes['dataSource'].currentValue as T[] | undefined) : undefined;
    if (val && Object.keys(val).length) {
      this.dataView = this.dataSource;
    }
  }

  get displayedColumns(): string[] {
    return this.columns.map(({ headerName }) => headerName);
  }
}
